<template>
  <div
    id="point-details"
  >
    <!-- Alert: No point found -->
    <b-alert
      variant="danger"
      :show="point === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('errors.data.error_fetching_request_data') }}
      </h4>
      <div class="alert-body">
        {{ $t('errors.data.no_request_found_with_this_id') }}.
      </div>
    </b-alert>
    <div v-if="point && point.id">
      <offer-header
        :proposal-can-be-added="proposalCanBeAdded"
        :proposal-added="proposalAdded"
      />
      <b-row>
        <b-col
          lg="8"
        >
          <div class="tabs">
            <b-nav tabs>
              <b-nav-item
                to="#"
                :active="$route.hash === '#' || $route.hash === ''"
              >
                <feather-icon
                  class="d-none d-sm-block"
                  icon="FileTextIcon"
                />
                <span>{{ $t('Details') }}</span>
              </b-nav-item>
            </b-nav>
            <div class="tab-content">
              <div
                :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]"
              >
                <b-card-text>
                  <offer-details />
                </b-card-text>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          lg="4"
        >
          <b-tabs>
            <b-tab
              v-if="ability.can('update', point)"
            >
              <template #title>
                <feather-icon
                  icon="UserIcon"
                  class="d-none d-sm-block"
                />
                <span>{{ $t('Account') }}</span>
              </template>

              <b-card
                class="text-center"
              >
                <div class="position-absolute statuses">
                  <b-badge
                    v-if="point.managed"
                    variant="light-danger"
                  >
                    <small>
                      {{ $t('point.hidden_to_contractors') }}
                      <feather-icon
                        icon="EyeOffIcon"
                      />
                    </small>
                  </b-badge>
                </div>
                <user-profile :user="point.user" />
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import {
  BAlert, BCardText, BNav, BNavItem, BRow, BCol, BTab, BTabs, BCard, BBadge,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { isMobile, formatTimeOfStay, getFormattedAddress } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { showToast } from '@/mixins/notification/toasts'
import UserProfile from '@/views/pages/account-setting/AccountSettingUserProfile.vue'
import OfferHeader from './details/OfferHeader.vue'
import OfferDetails from './details/OfferDetails.vue'

export default {
  components: {
    BAlert,
    BCardText,
    BNav,
    BNavItem,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BBadge,
    OfferHeader,
    OfferDetails,
    UserProfile,
  },
  mixins: [isMobile, formatTimeOfStay, getFormattedAddress, showToast],
  data() {
    return {
      totalProposals: null,
      proposalCanBeAdded: false,
      proposalAdded: false,
    }
  },

  methods: {
    updateTotalProposals(n) {
      this.totalProposals = n
    },
    updateProposalCanBeAdded(val) {
      this.proposalCanBeAdded = val
      this.proposalAdded = !val
    },
  },
  setup() {
    const {
      handleCartActionClick,
      togglePointInWishlist,
      resolvePointTypeBadgeVariant,
      handleEditRequestActionClick,
    } = usePointsUi()

    const point = ref(null)
    const mediaFiles = ref([])
    const ability = defineAbilityForCurrentUser()
    // Get point  id from URL
    const { route } = useRouter()
    const pointId = route.value.params.id

    // Remote Data
    const fetchPoint = () => {
      store.dispatch('point/fetchServiceOffer', { id: pointId })
        .then(response => {
          point.value = store.getters['point/Point']
          if (response.data.data.main_image !== null && typeof response.data.data.main_image) {
            mediaFiles.value.push(response.data.data.main_image.url)
          }
          if (typeof response.data.data.media_files !== 'undefined' && response.data.data.media_files.length > 0) {
            response.data.data.media_files.forEach(file => {
              mediaFiles.value.push(file.url)
            })
          }
          point.value.mediaFiles = mediaFiles.value
          store.commit('point/setPoint', point.value)
        })
        .catch(error => {
          point.value = undefined
        })
    }

    // UI
    const selectedColor = ref(null)

    fetchPoint()

    return {
      // Fetched Point
      point,
      ability,
      mediaFiles,

      // UI
      selectedColor,
      handleCartActionClick,
      resolvePointTypeBadgeVariant,
      handleEditRequestActionClick,
      togglePointInWishlist,
    }
  },
}

</script>
<style scoped>

.statuses{
  top: 0;
  right:0;
  margin-right: 0!important;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}

</style>
