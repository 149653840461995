<template>
  <b-card
    no-body
    class="header"
  >
    <b-row class="text-right">
      <b-col>
        <div class="actions">
          <b-button
            v-if="ability.can('update', headerData)"
            variant="primary"
            @click="$parent.handleEditOfferActionClick(headerData)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Edit') }}</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import defineAbilityFor from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText, isMobile } from '@/mixins/functions'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [getPostStatusProperty, getUserAvatarText, isMobile],
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
  },
  setup() {
    const user = store.getters['account/user']
    const ability = defineAbilityFor(user)
    return {
      ability,
    }
  },
}

</script>
<style scoped>
  .header .post-status {
    border-right: 1px solid #d8d6de;
    padding-right: 1.3rem;
    margin-right: 1.3rem;
  }
  .header .post-status  {
    border-right-color: #404656;
  }
  .actions>button{
    margin-left: 1.3rem;
  }
  .post-status{
    padding-left: 1em;
    padding-right: 1em;
  }
</style>
